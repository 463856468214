import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BaseURL } from "../../Helper/config";
import SliderProvider from "../Common/Slider/SliderProvider";
import FoodPresentationWithSeller from "../Common/Cards/FoodPresentationWithSeller/FoodPresentationWithSeller";
import FoodPresentationWithSellerFlat from "../Common/Cards/FoodPresentationWithSeller/FoodPresentationWithSellerFlat";

const AllFoodLayoutDesign = ({ data }) => {
    const [foodItems, setFoodItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const { coordinate, error } = useSelector((state) => state.location);

    // console.log(data);

    const { sectionCategories1, sectionFoodTypeCategories1, sectionTitle1, sectionCardColor } = data;
    console.log(sectionCategories1, sectionFoodTypeCategories1);

    const getFood = async () => {
        setLoading(true);

        const payload = {
            categoryID: [
                ...sectionCategories1.map((cat) => cat.value),
            ],
            foodType: [
                ...sectionFoodTypeCategories1.map((type) => type.value),
            ],
        };

        await axios
            .post(
                BaseURL + `/filter?lat=${coordinate.lat}&lon=${coordinate.lon}`,
                payload)
            .then((res) => {
                console.log("newSectionData", res);

                setFoodItems(res?.data?.data);
            });
        setLoading(false);

    }



    useEffect(() => {
        getFood();
    }, [data]);


    // console.log(foodItems);

    const settings = {
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 4,
    };

    return (
        <div className="AllFoodLayoutDesign">
       
            <div className="sf_text-theme container ">
                {
                    sectionTitle1 && <span style={{ fontFamily: 'var(--style-font)', color: '#1b6dc1', fontSize: '28px', fontWeight: '700' }}>{sectionTitle1}</span>
                }
            </div>


            {
                foodItems?.length > 0 && <div 
                // style={{ marginLeft: "60px", }}
                >
                    {
                        <SliderProvider
                            key="slider"
                            sliderSettings={settings}
                            food={foodItems}
                            cardComponent={
                                sectionCardColor === "WHITE"
                                    ? FoodPresentationWithSellerFlat
                                    : sectionCardColor === "MULTICOLOR"
                                        ? FoodPresentationWithSeller
                                        : FoodPresentationWithSeller
                                // sectionCardColor == "WHITE" &&
                                // FoodPresentationWithSellerFlat
                                // sectionCardColor=="MULTICOLOR" && 
                                // FoodPresentationWithSeller

                            }
                        />
                    }
                </div>
            }
        </div>
    )
}

export default AllFoodLayoutDesign